function headerFn() {
    const st = document.body.parentElement.scrollTop;
    const Header = document.querySelector('#pageHeader');
    const Content = document.querySelector('#pageContent')
    if (st > 0) {
        Header.classList.add('sticky-header');
        if (window.matchMedia('(max-width: 599px)').matches) {
            Header.style.width = '';
            Header.style.paddingLeft = '';
            Header.style.paddingRight = '';
        } else {
            if (window.matchMedia('(max-width: 1184px)').matches) {
                Header.style.width = (Content.getBoundingClientRect().width + 32) + "px";
            } else {
                Header.style.width = (Content.getBoundingClientRect().width + 32) + "px";
            }

            // Header.style.paddingLeft = '1rem';
            // Header.style.paddingRight = '1rem';
        }

    } else {
        const Header = document.querySelector('#pageHeader')
        Header.classList.remove('sticky-header');
        Header.style.width = '';

    }
}

window.addEventListener('scroll', headerFn);
window.addEventListener('resize', headerFn);

function trackJoin(loginType) {
    if (window.ga) {
        ga('send', 'event', 'login-cta', 'Click', `login-${loginType}`);
    }
}

let observer = null;

window.addEventListener('DOMContentLoaded', (event) => {
    var stickyElements = Array.from(document.querySelectorAll('.content-name div'));
    const Header = document.querySelector('#pageHeader');
    function getParentBox(el) {
        const y = el.parentElement.parentElement.getBoundingClientRect().top;
        const h = el.parentElement.parentElement.getBoundingClientRect().height;
        return {
            y,
            h,
            bottom: y + h
        };
    }

    function stickyFn() {
        const ST = document.body.parentElement.scrollTop;
        const CH = document.body.parentElement.clientHeight;
        const HH = Header.getBoundingClientRect().height;
        const els = stickyElements.filter(function(el) {
            const box = getParentBox(el);
            let inView = !((box.y < 0 && box.bottom < 0) || (box.y > CH));
            // if (!inView) {
                el.style.position = 'static';
                // el.style.transform = 'translateY(0)';
                // el.style.zIndex = 'initial';
            // }

            return inView
        });

        els.forEach(function(el) {
            const box = getParentBox(el);
            const FRINGE = HH;
            el.style.zIndex = '100';
            const isMobile = window.matchMedia('(max-width: 1184px)').matches;
            if (box.y > FRINGE || isMobile) {
                // el.style.top = (HH + 16) + 'px'
                el.style.position = 'static';
                // el.style.transform =  'translateY(16px)';
            } else {
                el.style.position = 'fixed';

                if (box.bottom >= HH + 128) {
                    el.style.top = (HH + 16) + 'px';
                } else {
                    const v = box.bottom - 64 - 45;
                    el.style.top = v + 'px';
                }

                // el.style.transform = 'translateY(' + Math.min(
                //     (box.y > 0 ? (FRINGE - box.y) : Math.abs(box.y) + HH),
                //     box.h - el.getBoundingClientRect().height - 64
                // ) + 'px)';
            }
        })
    }

    window.addEventListener('scroll', stickyFn);
    window.addEventListener('resize', stickyFn);

    // function buildThresholdList() {
    //     let thresholds = [];
    //     let numSteps = 1000000;
    //
    //     for (let i = 1.0; i<=numSteps; i++) {
    //         let ratio = i / numSteps;
    //         thresholds.push(ratio);
    //     }
    //
    //     thresholds.push(0);
    //     return thresholds;
    // }

    // window.WO = observer = new IntersectionObserver(stickyFn, {
    //     rootMargin: '0px',
    //     threshold: buildThresholdList()
    // });
    //
    // stickyElements.forEach(el => {
    //     observer.observe(el.parentElement.parentElement);
    // })

    // observer.observe(document.querySelector('.siteHomePage'))


    document.querySelector('.header--join-btn.sign-up').addEventListener('click', () => {
        document.querySelector('login-popup').show(false, 'login-register-form');
        trackJoin('header');
    });

    document.querySelector('.header--join-btn.sign-in').addEventListener('click', () => {
        document.querySelector('login-popup').show(false);
        trackJoin('header');
    });

    document.querySelector('.header--join-img-btn').addEventListener('click', () => {
        document.querySelector('login-popup').show(false);
        trackJoin('header');
    });


    function initJoinBtns() {
        let els = Array.from(document.querySelectorAll('.mentors-mentee-card--join'));
        els.forEach((el, ind) => {
            el.addEventListener('click', () => {
                document.querySelector('login-popup').show(ind !== 0);
                if (ind == 0) {
                    trackJoin('platform-mentee');
                } else {
                    trackJoin('platform-mentor');
                }
            });
        });
        els = Array.from(document.querySelectorAll('.ui-card--text.join'));
        els.forEach((el, ind) => {
            el.parentElement.addEventListener('click', () => {
                const asMentee = el.parentElement.innerText.trim().toLowerCase() == 'join as mentee';
                document.querySelector('login-popup').show(!asMentee);
                if (asMentee) {
                    trackJoin('hiw-mentee');
                } else {
                    trackJoin('hiw-mentor');
                }
            });
        })
    }

    initJoinBtns();

    const switchMeetee = document.querySelector('#a-switch-mentee');
    if (switchMeetee) {
        switchMeetee.addEventListener('click', (e) => {
            if (e.target.checked) {
                document.querySelector('#for-mentee-ui-cards').style.display = "block";
                document.querySelector('#for-mentor-ui-cards').style.display = "none";
                ga('send', 'event', 'how-it-works', 'Click', `hiw-for-mentee`);
            }
        })

        const switchMeetor = document.querySelector('#a-switch-mentor')
        switchMeetor.addEventListener('click', (e) => {
            if (e.target.checked) {
                document.querySelector('#for-mentee-ui-cards').style.display = "none";
                document.querySelector('#for-mentor-ui-cards').style.display = "block";
                ga('send', 'event', 'how-it-works', 'Click', `hiw-for-mentor`);
            }
        })

    }

    // document.querySelector('#nav-sub-for-mentee').addEventListener('click', (e) => {
    //     document.querySelector('#a-switch-mentee').checked = true;
    // })
    //
    // document.querySelector('#nav-sub-for-mentor').addEventListener('click', (e) => {
    //     document.querySelector('#a-switch-mentor').checked = true;
    // });

    let selectedInd = 1;
    Array.from(document.querySelectorAll('.content-block.future .left-text .kv-item')).forEach((el, ind) => {
        console.log('subscribing');
        el.addEventListener('click', () => {
            const sel = document.querySelector('.content-block.future .left-text .stroked');
            if (sel === el) return;
            sel.classList.remove('stroked');
            sel.classList.remove('animated');
            document.querySelector(`#sentence${selectedInd}`).classList.remove('visible');
            document.querySelector(`#sentence${selectedInd}`).classList.remove('animated');
            document.querySelector(`#sentence${selectedInd}`).classList.remove('stroked');
            document.querySelector(`#sentence${ind + 1}`).classList.add('animated');
            document.querySelector(`#sentence${ind + 1}`).classList.add('visible');

            document.querySelector(`#kv-slider`).style.transform = `translateY(${-ind * 26}rem)`
            el.classList.add('animated');
            setTimeout(() => {
                el.classList.add('stroked');
                document.querySelector(`#sentence${ind + 1}`).classList.add('stroked');
            }, 10);

            selectedInd = ind + 1;
        })
    })

    const contentBlocks = Array.from(document.querySelectorAll('.content-block'));
    if (contentBlocks.length === 0) {
        return;
    }
    const baseH = contentBlocks[0].querySelector('.content-name').getBoundingClientRect().height;
    const ZValue = document.querySelector('#pageHeader').getBoundingClientRect().height;
    window.addEventListener('scroll', () => {
        return;
        const H = document.documentElement.clientHeight;
        const activeContentBlock = contentBlocks.filter(cb => {
            const rect = cb.getBoundingClientRect();
            const bottom = rect.top + rect.height;
            if ((rect.top >= ZValue && rect.top <= H) || (rect.top < ZValue && ((bottom > ZValue && bottom <= H) || bottom > H))) {
                return true;
            }

            return false;
        })
        activeContentBlock.forEach(acb => {
            const cname = acb.querySelector('.content-name');
            const cnameRect = cname.getBoundingClientRect();
            const rect = acb.getBoundingClientRect();
            let tvalue = 0;
            if (rect.top < ZValue) {
                tvalue = -rect.top + baseH / 2;
                tvalue = Math.min(tvalue, rect.height - 32 - baseH / 2);
            }
            cname.style.transform = `translateY(${tvalue}px)`;
        })
    });
});

var wiredMode = false;
document.addEventListener('keyup', (e) => {
    if (e.ctrlKey && e.key === 'k') {
        wiredMode = !wiredMode;
    }

    let el = document.querySelector('#wired-style');
    if (el) {
        el.parentElement.removeChild(el);
    }


    if (wiredMode) {
        let st = document.createElement('style');
        st.setAttribute('id', 'wired-style');
        st.innerHTML = `
            div, section, span, button, p,
            footer, header, main, svg,
            h1, h2, h3, h4, h5, h6 {
                background: #fff !important;
                background-color: #fff !important;
                box-shadow: none;
                box-sizing: border-box;
                border: 1px dashed rgba(200, 100, 100, 0.8) !important;
                color: rgba(200, 100, 100, 0.8) !important;
            }
        `
        document.body.appendChild(st);
    }

})
